<template>
    <div class="notifiaction">
        <div class="notifiaction-header">
            <p>{{ notifiaction.headline }}</p>
            <span>
                {{ time }}
                <svg @click="$emit('close')" width="13" height="13" viewBox="0 0 13 13" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L12 12M12 1L1 12" stroke="#9C9C9C" stroke-width="1.5" stroke-linecap="round" />
                </svg>
            </span>
        </div>
        <div clas="notifiaction-body">
            {{ notifiaction.text }}
        </div>
    </div>
</template>

<script>
import audio from '../../public/audio/tgchecker_notification_sound.ogg';

export default {
    name: "NotificationComponent.vue",
    props: {
        notifiaction: Object
    },
    emits: ['close'],
    data() {
        return {
            time: '',
            audio: audio
        }
    },
    methods: {
        parseTime(time) {
            const date = new Date(time);
            const hours = date.getHours();
            const minutes = date.getMinutes();

            return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
        },
        playSound(url) {
            const audio = new Audio(url);
            audio.muted = false;
            audio.play();
        }
    },
    watch: {
        notifiaction() {
            this.playSound(this.audio);
        }
    },
    mounted() {
        this.$store.dispatch('initTheme'); // Инициализация темы при создании компонента

        this.time = this.parseTime(this.notifiaction.created_at);
        this.playSound(this.audio);
    }
}
</script>

<style lang="scss" scoped>
[data-theme="dark"] {
    .notifiaction {
        background: #232C36;

        &-header {

            p {
                color: #FFFFFF;
            }

            span {
                color: #747A80;
            }
        }

        &-body {
            color: #FFFFFF;
        }
    }
}

.notifiaction {
    position: fixed;
    bottom: 50px;
    left: 50px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 24px;
    border-radius: 12px;
    max-width: 550px;
    width: 80%;
    background: #F2F2F2;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 15px;
            font-weight: 700;
            line-height: 24px;
            color: #242424;
        }

        span {
            display: flex;
            align-items: center;
            gap: 16px;
            font-size: 15px;
            font-weight: 300;
            line-height: 24px;
            color: #999999;

            svg {
                cursor: pointer;
                margin-top: -2px;
            }
        }
    }

    &-body {
        font-size: 15px;
        font-weight: 300;
        line-height: 24px;
        color: #242424;
    }
}
</style>