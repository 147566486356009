<template>
    <footer class="themefooter text-neutral-content items-center p-4">
        <aside class="grid-flow-col items-center">
            <p>© TG-Service, {{ new Date().getFullYear() }}</p>
            <!-- <p>ИП Чекин Вадим Васильевич</p>
          <p>ИНН 343301526646</p>
          <p>ОГРНИП 324344300026232</p> -->
        </aside>
        <nav>
            <a href="/policy">Пользовательское соглашение</a>
            <a :href="link" target="_blank">Поддержка</a>
        </nav>
    </footer>
</template>

<script>
import axios from 'axios';

export default {
    name: "footerComp",
    data() {
        return {
            link: '/'
        }
    },
    created() {
        axios.get('https://tg-checker.com/api/support_link', {
            withCredentials: true
        }).then(res => this.link = res.data.link);
    }
}
</script>

<style lang="scss" scoped>
footer {
    width: 100%;
    padding: 1rem 0 1rem 0;
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    color: #999999;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;

    nav {
        display: flex;
        gap: 20px;
    }
}
</style>