<template>
  <header class="header">
    <router-link class="logo cursor-pointer text-white font-semibold" to="/">
      <h3 class="logo-link text-base-content">
        TG<span class="logo-link__point">•</span>Service
      </h3>
    </router-link>
    <nav class="header__nav">
      <ul class="header__nav-list">
        <li
          class="header__nav-list-item inline-block cursor-pointer rounded-md px-5 py-2.5 text-center text-sm text-grey transition duration-200 ease-in-out "
          v-for="(link, index) in links" :key="index" @click="$router.push(link.href)">
          <RouterLink class="header__nav-list-item-link" :to="link.href">{{ link.name }}</RouterLink>
        </li>
        <li class="header__nav-list-item">
          <div v-if="$store.state.meActive" class="notifications-icon" @click="$router.push('/notifications')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.3 21C10.4674 21.3044 10.7134 21.5583 11.0125 21.7352C11.3115 21.912 11.6526 22.0053 12 22.0053C12.3474 22.0053 12.6885 21.912 12.9875 21.7352C13.2866 21.5583 13.5326 21.3044 13.7 21M6 8C6 6.4087 6.63214 4.88258 7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8C18 15 21 17 21 17H3C3 17 6 15 6 8Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            {{ notificationsCount }}
          </div>
        </li>
        <li class="header__nav-list-item theme">
          <div class="theme-changer" :class="{ light: !isDark, dark: isDark }" @click="toggleTheme">
            <div class="switch"></div>
            <svg style="position: absolute; left: 4px; top: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2V4M12 20V22M4.93 4.93L6.34 6.34M17.66 17.66L19.07 19.07M2 12H4M20 12H22M6.34 17.66L4.93 19.07M19.07 4.93L17.66 6.34M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg style="position: absolute; right: 4px; top: 4px;" width="24" height="24" viewBox="0 0 24 24"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 3C10.8065 4.19347 10.136 5.81217 10.136 7.5C10.136 9.18783 10.8065 10.8065 12 12C13.1935 13.1935 14.8122 13.864 16.5 13.864C18.1878 13.864 19.8065 13.1935 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3Z"
                stroke="#17212B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </li>
      </ul>
    </nav>
    <div class="tab z-20">
      <div v-if="$store.state.meActive" class="notifications-icon" @click="$router.push('/notifications')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3 21C10.4674 21.3044 10.7134 21.5583 11.0125 21.7352C11.3115 21.912 11.6526 22.0053 12 22.0053C12.3474 22.0053 12.6885 21.912 12.9875 21.7352C13.2866 21.5583 13.5326 21.3044 13.7 21M6 8C6 6.4087 6.63214 4.88258 7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8C18 15 21 17 21 17H3C3 17 6 15 6 8Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ notificationsCount }}
      </div>
      <label class="btn btn-circle swap swap-rotate">
        <!-- this hidden checkbox controls the state -->
        <input type="checkbox" @click="toggleDialog" />

        <!-- hamburger icon -->
        <svg class="swap-off fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
          viewBox="0 0 512 512">
          <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
        </svg>

        <!-- close icon -->
        <svg class="swap-on fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
          viewBox="0 0 512 512">
          <polygon
            points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
        </svg>
      </label>
    </div>
  </header>
</template>

<script>
import { RouterLink } from 'vue-router';
import { mapState, mapMutations } from 'vuex';

export default {
  name: "headerComp",
  components: {
    RouterLink
  },
  props: {
    links: Array,
    notificationsCount: Number
  },
  computed: {
    ...mapState(['isDark']), // Подключение глобального состояния для отслеживания текущей темы
  },
  methods: {
    ...mapMutations(['toggleTheme']),
    toggleDialog() {
      this.$emit('toggle-dialog'); // Эмитим событие при клике
    }
  },
  async mounted() {
    this.$store.dispatch('initTheme'); // Инициализация темы при создании компонента
  },
};
</script>

<style lang="scss" scoped>
[data-theme="dark"] {
  .notifications-icon {
    background: #FFFFFF !important;
    color: #17212B !important;

    path {
      stroke: #17212B !important;
    }
  }
}

.notifications-icon {
  display: flex;
  background: #242424;
  padding: 8px 20px;
  border-radius: 12px;
  gap: 12px;
  align-items: center;
  color: #FFFFFF;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 640px) {
    padding: 6px 12px;
    gap: 8px;
  }
}

.toggle {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.header {
  width: 100%;
  padding: 1.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .logo {
    &-link {
      display: inline-flex;
      gap: 3px;
      font-size: 1.25rem;

      &__point {
        color: #419FD9;
        font-size: 1.25rem;
      }
    }
  }

  &__nav {
    width: auto;
    height: auto;

    &-list {
      display: inline-flex;
      align-items: center;
      gap: 20px;
      font-weight: 200;
      color: grey;

      &-item:hover:not(.theme) {
        background: rgb(203, 213, 225, 0.1);
      }
    }
  }

  .tab {
    display: none; // Скрыть гамбургер меню по умолчанию

    @media (max-width: 1032px) {
      display: flex; // Показать гамбургер меню на мобильных устройствах
      gap: 24px;
    }
  }

  .header__nav {
    display: flex; // Показывать навигацию по умолчанию

    @media (max-width: 1032px) {
      display: none; // Скрыть навигацию на мобильных устройствах
    }
  }

  @media (max-width: 1032px) {
    padding: 1.5rem 0rem 1.5rem 1.5rem;
  }
}

.theme-changer {
  width: 60px;
  height: 32px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .switch {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    transition: all 0.2s ease-in-out;
    z-index: 1000;
  }

  &.light {
    background: #EFCA08;

    .switch {
      background: #FFFFFF;
      top: 4px;
      left: 32px;
    }
  }

  &.dark {
    background: #FDF4C4;

    .switch {
      background: #17212B;
      top: 4px;
      left: 4px;
    }
  }
}
</style>
