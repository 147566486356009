<template>
  <div id="dialog" class="z-10">
    <nav class="header__nav">
      <ul class="header__nav-list flex flex-col justify-center content-center gap-4">
        <li
          class="header__nav-list-item inline-block cursor-pointer rounded-md px-5 py-2.5 text-center text-sm text-grey transition duration-200 ease-in-out hover:text-white hover:bg-slate-500"
          v-for="(link, index) in links" :key="index">
          <a class="header__nav-list-item-link" :href="link.href">{{ link.name }}</a>
        </li>
        <li class="header__nav-list-item theme">
          <div class="theme-changer" :class="{ light: !isDark, dark: isDark }" @click="toggleTheme">
            <div class="switch"></div>
            <svg style="position: absolute; left: 4px; top: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2V4M12 20V22M4.93 4.93L6.34 6.34M17.66 17.66L19.07 19.07M2 12H4M20 12H22M6.34 17.66L4.93 19.07M19.07 4.93L17.66 6.34M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg style="position: absolute; right: 4px; top: 4px;" width="24" height="24" viewBox="0 0 24 24"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 3C10.8065 4.19347 10.136 5.81217 10.136 7.5C10.136 9.18783 10.8065 10.8065 12 12C13.1935 13.1935 14.8122 13.864 16.5 13.864C18.1878 13.864 19.8065 13.1935 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3Z"
                stroke="#17212B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';


export default {
  name: "dialogNavComp.vue",
  props: {
    links: Array
  },
  computed: {
    ...mapState(['isDark']), // Подключение глобального состояния для отслеживания текущей темы
  },
  methods: {
    ...mapMutations(['toggleTheme']), // Использование мутации для переключения темы
  },
  created() {
    this.$store.dispatch('initTheme'); // Инициализация темы при создании компонента
  },
}
</script>

<style lang="scss" scoped>
.toggle {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

#dialog {
  width: 100%;
  height: 100dvh;
  position: absolute;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--fallback-b1, oklch(var(--b1) / 1));
  color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.theme {
  display: flex;
  justify-content: center;
}

.theme-changer {
  width: 60px;
  height: 32px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .switch {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    transition: all 0.2s ease-in-out;
    z-index: 1000;
  }

  &.light {
    background: #EFCA08;

    .switch {
      background: #FFFFFF;
      top: 4px;
      left: 32px;
    }
  }

  &.dark {
    background: #FDF4C4;

    .switch {
      background: #17212B;
      top: 4px;
      left: 4px;
    }
  }
}
</style>