<template>
    <NotificationComponent v-if="isShown" :notifiaction="currentNotification" @close="isShown = false" class="not-faded"
        :class="{ 'fade': timeLeft < 3 }" />
</template>

<script>
import NotificationComponent from './NotificationComponent.vue';
import axios from 'axios';

export default {
    name: "NotificationWrapper.vue",
    components: {
        NotificationComponent
    },
    data() {
        return {
            isShown: false,
            timeLeft: 10,
            currentNotification: {},
            notifications: [],
        }
    },
    methods: {
        setNotification() {
            if (!this.currentNotification?.created_at) {
                if (this.notifications.length > 0) {
                    this.currentNotification = this.notifications[0];
                    this.notifications.splice(0, 1);
                    this.isShown = true;

                    const interval = setInterval(() => {
                        this.timeLeft -= 1;
                    }, 1000);

                    setTimeout(() => {
                        clearInterval(interval);
                        this.timeLeft = 10;
                        this.isShown = false;
                        this.currentNotification = {};

                        this.setNotification();
                    }, 10500);
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.$store.state.meActive) {
                setInterval(() => {
                    axios.get('https://tg-checker.com/api/onetime_notifications', {
                        withCredentials: true
                    }).then(res => {
                        if (res.data) {
                            this.notifications = [...res.data.data, ...this.notifications];
                            this.setNotification();
                        }
                    }).catch(err => console.log(err));
                }, 5000);
            }
        }, 1000);
    }
}
</script>

<style lang="scss" scoped>
.not-faded {
    opacity: 1;
    transition: all 2s ease-in-out;
}

.fade {
    opacity: 0;
}
</style>